import { currentUser } from "../../constants/config";
import { doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { firebaseDB } from "@/services/Firebase";

export default {
  namespaced: true,
  state: {
    currentUser:
      localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    verificationCodeSuccess: null,
    resetPasswordSuccess: null,
    isAuthorized: false,
    snapshot: false,
  },
  getters: {
    currentUser: (state) => state.currentUser,
    processing: (state) => state.processing,
    loginError: (state) => state.loginError,
    forgotMailSuccess: (state) => state.forgotMailSuccess,
    verificationCodeSuccess: (state) => state.verificationCodeSuccess,
    resetPasswordSuccess: (state) => state.resetPasswordSuccess,
    isAdmin: (state) => () => {
      return (
        state.currentUser && state.currentUser.role.indexOf("admin") !== -1
      );
    },
  },
  actions: {
    async register({ state, commit, dispatch }, formData) {
      commit("setProcessing", true);
      return new Promise((resolve, reject) => {
        const pwd = formData.password;
        const data = {
          firstName: formData.firstName || "",
          lastName: formData.lastName || "",
          email: formData.email || "",
          phone: formData.phone || "",
          postal: formData.postal || "",
          street: formData.street || "",
          city: formData.city || "",
        };

        data.name = `${data.firstName} ${data.lastName}`;
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, data.email, pwd)
          .then((userCredential) => {
            const user = userCredential.user;
            const docRef = doc(firebaseDB, "users", user.uid);
            let item = {
              id: user.uid,
              name: user.displayName,
              uid: user.uid,
              email: user.email,
              ...currentUser,
            };
            setDoc(docRef, data)
              .then(() => {
                state.requesting = false;
                data.uid = user.uid;
                item = { ...item, ...data };

                commit("setUser", item);
                commit("setProcessing", false);
                dispatch("initUserSnapshot");
                resolve(true);
              })
              .catch((error) => {
                state.requesting = false;
                reject(error);
              });
          })
          .catch((error) => {
            const errorMessage = error.message;
            commit("setProcessing", false);
            reject(errorMessage);
          });
      });
    },

    async login({ commit, dispatch }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, payload.email, payload.password)
          .then((userCredential) => {
            const user = userCredential.user;
            dispatch("initUserSnapshot", user.uid).then(() => {
              resolve(true);
            });
          })
          .catch((error) => {
            const errorMessage = error.message;
            commit("setError", errorMessage);
            commit("setProcessing", false);

            reject("Ungültiger Benutzername / Passwort.");
          });
      });
    },

    async update({ state }, data) {
      const docRef = doc(firebaseDB, `users/${state.currentUser.uid}`);
      data.name = `${data.firstName} ${data.lastName}`;
      return updateDoc(docRef, data);
    },

    async initUserSnapshot({ commit, state }, uid) {
      return onSnapshot(doc(firebaseDB, "users", uid), (doc) => {
        // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        // console.log(source, " data: ", doc.data());
        if (doc.exists()) {
          const userRecord = doc.data();
          userRecord.uid = uid;
          state.snapshot = true;
          commit("setUser", userRecord);
        }
      });
    },

    setAuth({ commit }, payload) {
      commit("setUser", payload);
    },
    unsetAuth() {
      localStorage.removeItem("user");
    },
    lockScreen({ commit }, status) {
      return new Promise((resolve) => {
        commit("setUnlock", status);
        resolve(true);
      });
    },
    async forgotPassword({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);

      return new Promise((resolve, reject) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, payload.email)
          .then(() => {
            commit("setProcessing", false);
            resolve(true);
          })
          .catch(() => {
            commit("setProcessing", false);
            reject("Ungültige E-Mail");
          });
      });
    },

    signOut({ commit, dispatch }) {
      localStorage.removeItem("user");
      dispatch("reservation/detach", null, { root: true });
      commit("setLogout");
    },
    attachAuthUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  mutations: {
    setUser(state, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },

    updateUser(state, payload) {
      const user = { ...state.currentUser, ...payload };
      state.currentUser = user;
      localStorage.setItem("user", JSON.stringify(user));
    },

    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.forgotMailSuccess = null;
      state.verificationCodeSuccess = null;
      state.resetPasswordSuccess = null;
      state.loginError = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    clearError(state) {
      state.loginError = null;
    },
  },
};
