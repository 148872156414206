import { createStore } from "vuex";

import event from "./modules/event";
import show from "./modules/show";
import reservation from "./modules/reservation";
import booking from "./modules/booking";
import auth from "./modules/auth";

export default createStore({
  modules: {
    auth,
    event,
    reservation,
    show,
    booking,
  },
  strict: false,
});
