<template>
  <button
    class="border-6 px-[5rem] py-3 text-[24px] border-n3yellow inline-block disabled:opacity-50"
    :class="classes"
    :disabled="disable"
    href="javascript:void(0)"
    @click.prevent="clicked"
  >
    <svg
      class="animate-spin w-5 mr-3 inline-block"
      viewBox="0 0 24 24"
      v-if="loading"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    {{ label }}
  </button>
</template>
<script>
export default {
  name: "DefaultButton",
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    function clicked() {
      emit("clicked");
    }

    return {
      clicked,
    };
  },
};
</script>
