<template>
  <router-view />

  <timer />
</template>
<script>
import Timer from "./components/Timer.vue";
export default {
  components: { Timer },
};
</script>
<style>
@import "https://use.typekit.net/qmy5ufi.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
</style>
<style lang="scss">
@import "@sweetalert2/theme-dark/dark.scss";
</style>
