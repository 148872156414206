import auth from "@/middleware/auth";
import handleMobile from "@/middleware/handleMobile";

export default [
  {
    path: "/account",
    meta: { middleware: [auth, handleMobile] },
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/account/IndexPage.vue"
      ),
    children: [
      {
        path: "",
        name: "accountHome",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/Profile.vue"
          ),
      },
      {
        path: "tickets",
        name: "accountTicket",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/ticket/Index.vue"
          ),
      },
      {
        path: "ticket",
        name: "accountTicketDetail",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/ticket/Detail.vue"
          ),
      },

      {
        path: "password",
        name: "accountPassword",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/Password.vue"
          ),
      },
    ],
  },
];
