<template>
  <div
    v-if="reservation && reservation.id && !stripeSession"
    class="reservation-timer text-n3yellow"
  >
    <div class="text-center" v-if="timeDiff">
      <span>{{ addZero(minutes, 2) }}:{{ addZero(seconds, 2) }}</span>
    </div>
    <div class="text-center" v-if="!timeDiff">Time's Up!</div>
    <div class="tip">To complete your reservation</div>
  </div>
</template>

<script>
import { addZero } from "@/utils/helper";
import { useStore } from "vuex";

import Swal from "sweetalert2";

import { computed, onMounted, reactive, toRefs, watch } from "vue";
export default {
  name: "ReservationTimer",
  props: {
    deadline: {
      type: String,
      required: false,
    },
    speed: {
      type: Number,
      default: 1000,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      timeDiff: 100,
      reservation: computed(() => store.state.reservation.reservation),
      reservationID: computed(() => store.state.reservation.reservationID),
      stripeSession: computed(() => store.state.reservation.stripeSession),
      expireTime: computed(() => store.getters["reservation/expireTime"]),
      seconds: computed(() => Math.floor((state.timeDiff / 1000) % 60)),
      minutes: computed(() => Math.floor((state.timeDiff / 1000 / 60) % 60)),
    });

    const countdown = () => {
      state.timeDiff = Date.parse(state.expireTime) - Date.parse(new Date());
      if (state.timeDiff > 0) {
        setTimeout(countdown, props.speed);
      } else {
        state.timeDiff = null;
      }
    };
    const clearReservation = () => {
      if (!state.timeDiff && state.reservation.id && !state.stripeSession) {
        Swal.fire({
          title: "Time up!",
          html: "We regret to inform you that the 10 minutes booking window for your reservation has expired. To secure your seats, please start a new reservation.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        store.dispatch("reservation/detach");
      }
    };
    watch(
      () => state.seconds,
      () => {
        clearReservation();
      }
    );

    watch(
      () => state.reservationID,
      () => {
        state.timeDiff = Date.parse(state.expireTime) - Date.parse(new Date());
        clearReservation();
        countdown();
      }
    );

    onMounted(() => {
      setTimeout(countdown, 1000);
      clearReservation();
    });

    return {
      ...toRefs(state),
      addZero,
    };
  },
};
</script>
<style lang="scss">
.reservation-timer {
  cursor: pointer;
  position: fixed;
  top: 20%;
  right: 0;
  padding: 6px 23px;
  font-size: 28px;
  border-radius: 40px 0 0 40px;
  border-right: 0;
  box-shadow: -3px -1px 9px 3px #0d0d0d7a;
  font-weight: bold;
  -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
  transition: width 5s;
  border: 3px solid;
  border-right: none;
  z-index: 999;

  &:hover {
    width: 200px;
  }
  &:hover .tip {
    display: block;
  }
  .tip {
    display: none;
    font-size: 20px;
    text-align: center;
  }
}
</style>
