<template>
  <div class="n3-carousal">
    <ul class="carousel-list">
      <li
        class="carousel-item"
        :class="{ active: activeIndex === index }"
        v-for="(item, index) in sliders"
        :key="index"
      >
        <div>
          <div
            class="image"
            :style="`background-image: url(${item.posterUrlLandscape});`"
          ></div>
          <div class="container mx-auto">
            <div class="slider-content">
              <div class="pt-10 mb-1 text-[24px] uppercase leading-1">
                {{ item.subtitle }}
              </div>
              <div class="text-[60px] font-medium uppercase ml-[-5px]">
                {{ item.title }}
              </div>
              <div class="text-[26px]">
                From
                {{ dateTimeFormat(item.date, "ddd Do MMM YYYY") }}
              </div>

              <div class="mt-5">
                <default-button
                  label="More info"
                  @click="
                    $router.push({
                      name: 'eventDetail',
                      params: { eventId: item.eventId },
                    })
                  "
                />
                <div class="float-right" v-if="sliders.length > 1">
                  <div class="slider-nav">
                    <div class="slider-nav-item-left" @click="previous()">
                      <img src="@/assets/icons/arrow-left.png" alt="" />
                    </div>
                    <div class="slider-nav-item-right" @click="next()">
                      <img src="@/assets/icons/arrow-right.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-overlay"></div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, reactive, ref, toRefs } from "vue";
import DefaultButton from "./button/DefaultButton.vue";
import { useStore } from "vuex";
import { dateTimeFormat } from "@/utils/helper";
export default {
  components: { DefaultButton },
  name: "N3Carousel",
  props: {
    timeout: {
      type: Number,
      default: 3000,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    let activeIndex = ref(0);
    let images = ref([]);
    let interval = null;

    const state = reactive({
      events: computed(() => store.state.event.events),
      sliders: computed(() => {
        return state.events;
      }),
    });

    onMounted(() => {
      // images.value = [
      //   {
      //     src: require("@/assets/img/banner.png"),
      //     url: "/event/high-on-u1",
      //   },
      //   {
      //     src: require("@/assets/img/banner-2.jpeg"),
      //     url: "/event/high-on-u1",
      //   },
      // ];
      if (images.value.length > 1 && props.autoplay) {
        interval = setInterval(() => {
          next();
        }, props.timeout);
      }
    });

    onUnmounted(() => {
      if (interval && props.autoplay) clearInterval(interval);
    });

    function next() {
      const total = state.sliders.length;
      if (total - 1 === activeIndex.value) {
        activeIndex.value = 0;
      } else {
        activeIndex.value++;
      }
    }

    function previous() {
      const total = state.sliders.length;
      if (activeIndex.value === 0) {
        activeIndex.value = total - 1;
      } else {
        activeIndex.value--;
      }
    }

    return {
      ...toRefs(state),
      dateTimeFormat,
      activeIndex,
      images,
      next,
      previous,
    };
  },
};
</script>
<style lang="scss">
.n3-carousal {
  // position: relative;
  height: 100vh;
  ul.carousel-list {
    .carousel-item {
      display: none;
      &.active {
        display: block;
      }
      .image {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
      }
      img {
        width: 100%;
      }

      .slider-content {
        width: 100%;
        margin-top: -400px;
        position: relative;
        z-index: 999;
      }
      .slider-overlay {
        content: "";
        height: 700px;
        background: linear-gradient(0deg, rgb(9, 0, 0), transparent);
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  .slider-nav {
    display: flex;
    .slider-nav-item-left,
    .slider-nav-item-right {
      cursor: pointer;
    }
    .slider-nav-item-left {
      margin-right: 2.5rem;
    }
    .slider-nav-item-right {
      margin-left: 2.5rem;
    }
  }
}
</style>
