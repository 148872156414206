import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/custom.scss";

require("./bootstrap");

import axios from "axios";
import VueAxios from "vue-axios";

// import "sweetalert2/src/sweetalert2.scss";

const app = createApp(App);
app.use(store);
app.use(router);

app.use(VueAxios, axios);

app.mount("#app");
