import handleMobile from "@/middleware/handleMobile";
import HomeView from "../views/HomeView.vue";

export default [
  {
    path: "/",
    name: "home",
    meta: { middleware: [handleMobile] },
    component: HomeView,
  },
  {
    path: "/download-our-app",
    meta: { middleware: [handleMobile] },
    name: "downloadApp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DownloadApp.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "404",
    authRequired: true,
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/errors/404.vue"),
  },
];
