import store from "./store";

if (
  !store.state.auth.snapshot &&
  store.state.auth.currentUser &&
  store.state.auth.currentUser.uid
) {
  store.dispatch("auth/initUserSnapshot", store.state.auth.currentUser.uid);
}

if (store.state.reservation.reservationID) {
  store.dispatch("reservation/findReservationWithSnapshot");
}
