<template>
  <div class="bg-black pt-10">
    <div class="container mx-auto">
      <div class="grid grid-cols-6 grid-flow-col" v-if="false">
        <div class="col-span-2 text-white uppercase text-2xl">
          <div class="flex items-center">
            <div class="w-[90px] mr-5">
              <img src="@/assets/img/logo.png" alt="" />
            </div>
            <div>
              <div>Because the fun can start</div>
              <div>Before the entrance</div>
            </div>
          </div>
        </div>

        <div class="col-span-2 text-white uppercase text-2xl">
          <div class="grid justify-items-center" v-if="false">
            <div class="flex space-x-10">
              <div class="n3-icon facebook"></div>
              <div class="n3-icon instagram"></div>
              <div class="n3-icon twitter"></div>
            </div>
          </div>
        </div>
        <div class="col-span-2 text-white uppercase text-2xl">
          <div class="text-right">
            <div class="inline-block mr-10">
              <img src="../assets/img/app-store.png" alt="" class="h-[60px]" />
            </div>
            <div class="inline-block">
              <img
                src="../assets/img/google-play.png"
                alt=""
                class="h-[60px]"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-4 grid-flow-col footer-nav">
        <div class="col-span-2">
          <img src="@/assets/img/logo.png" alt="" class="w-[100px]" />
        </div>
        <div class="col-span-2">
          <div class="title">Your Events</div>
          <ul>
            <li>
              <a
                href=""
                @click.prevent="$router.push({ name: 'accountTicket' })"
                >Your Ticket</a
              >
            </li>
            <li>
              <a href="" @click.prevent="$router.push({ name: 'accountHome' })"
                >Your Account</a
              >
            </li>
          </ul>
        </div>

        <div class="col-span-2">
          <div class="title">Others</div>
          <ul>
            <li>
              <a href="https://n3.app/privacy.html" target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a href="https://n3.app/agb.html" target="_blank"
                >Term & Conditions</a
              >
            </li>
          </ul>
        </div>

        <div class="col-span-2">
          <div class="title">About</div>
          <ul>
            <li><a href="">Contact Us</a></li>
            <li><a href="">Imprint</a></li>
          </ul>
        </div>

        <div class="col-span-2">
          <div class="title">Any Questions?</div>
          <a href="mailto:support@n3.app" class="text-n3yellow text-[25px]"
            >support@n3.app</a
          >
        </div>

        <div class="col-span-2">
          <div class="title">Download our App</div>

          <div class="flex">
            <a href="https://n3.app/ios" target="_blank" class="mr-2">
              <img src="../assets/img/app-store.svg" alt="" class="w-[120px]" />
            </a>
            <a href="https://n3.app/android" target="_blank">
              <img
                src="../assets/img/google-play.svg"
                alt=""
                class="w-[120px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="n3-footer-copyright">
      Copyright &copy; {{ currentDateTime("YYYY") }} N3
    </div>
  </div>
</template>
<script>
import { currentDateTime } from "@/utils/helper";
export default {
  name: "FooterPage",
  setup() {
    return {
      currentDateTime,
    };
  },
};
</script>
<style lang="scss">
.n3-icon {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url("@/assets/img/social-media.png");
  &.facebook {
    background-position: 0 0;
  }
  &.instagram {
    background-position-y: -66px;
  }
  &.twitter {
    background-position-y: -130px;
  }
}
.footer-nav {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  .title {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    color: #7c7c7c;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li a {
      font-size: 22px;
      color: #fff;
    }
  }
}
.n3-footer-copyright {
  text-align: center;
  color: #7c7c7c;
  padding: 20px;
}
</style>
