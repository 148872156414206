import { firebaseDB } from "@/services/Firebase";
import { AccountBookingComponent } from "@/utils/enum";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

const state = {
  booking: {},
  bookings: [],
  ticketComponent: AccountBookingComponent.UPCOMING_BOOKING,
};

const getters = {
  unloadedBookings: (state) => state.bookings.filter((a) => !a.loaded),
  pastBookings: (state) => {
    return state.bookings.filter((booking) => {
      if (booking.date) {
        const date = new Date(booking.event.date.toDate());
        if (Date.now() > date.getTime()) {
          return booking;
        }
      } else {
        return true;
      }
    });
  },

  upcomingBookings: (state) => {
    return state.bookings.filter((booking) => {
      if (booking.date) {
        const date = new Date(booking.event.date.toDate());
        if (Date.now() < date.getTime()) {
          return booking;
        }
      } else {
        return true;
      }
    });
  },
};

const actions = {
  async fetch({ commit }, booking) {
    const docRef = doc(
      firebaseDB,
      `screenings/${booking.eventId}/reservations`,
      booking.reservationId
    );
    booking.fetching = true;
    const docSnap = await getDoc(docRef);

    booking.fetching = false;

    if (docSnap.exists()) {
      booking.loaded = true;
      commit("update", { ...booking, ...docSnap.data() });
    }
  },

  async fetchAll({ getters, dispatch }) {
    getters.unloadedBookings.forEach((booking) => {
      dispatch("fetch", booking);
    });
  },

  async fetchTickets({ state, commit }) {
    const querySnapshot = await getDocs(
      collection(
        firebaseDB,
        `screenings/${state.booking.eventId}/reservations/${state.booking.reservationId}/tickets`
      )
    );
    const b = state.booking;
    const tickets = [];
    querySnapshot.forEach((doc) => {
      tickets.push({ ...{ id: doc.id }, ...doc.data() });
    });

    b.ticketList = tickets;

    commit("update", b);
  },

  addBookings({ commit }, bookings) {
    commit("setBookings", bookings);
  },
  setState({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setState", payload);
      resolve(true);
    });
  },
};

const mutations = {
  setBookings(state, bookings) {
    state.bookings = bookings;
  },

  setState(state, payload) {
    state[payload.state] = payload.value;
  },
  update(state, booking) {
    const index = state.bookings.findIndex(
      (a) => a.reservationId === booking.reservationId
    );
    state.bookings[index] = booking;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
