import guest from "@/middleware/guest";
import handleMobile from "@/middleware/handleMobile";

export default [
  {
    path: "/auth",
    name: "auth",
    meta: { middleware: [guest, handleMobile] },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/IndexPage.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/SignIn.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/SignUp.vue"),
      },
    ],
  },
];
