import { createRouter, createWebHistory } from "vue-router";
import home from "./home";
import auth from "./auth";
import event from "./event";
import account from "./account";
import middlewarePipeline from "./middlewarePipeline";

const routes = [...home, ...auth, ...event, ...account];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to,
    };

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  }

  return next();
});

export default router;
