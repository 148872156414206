import auth from "@/middleware/auth";
import existingCheckout from "@/middleware/existingCheckout";
import existingReservation from "@/middleware/existingReservation";
import handleMobile from "@/middleware/handleMobile";

export default [
  {
    path: "/event",
    name: "events",
    authRequired: true,
    meta: { middleware: [handleMobile] },
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/event/IndexPage.vue"),
    children: [
      {
        path: ":eventId",
        name: "eventDetail",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/DetailPage.vue"
          ),
      },
      {
        path: ":eventId/:showId/seat",
        name: "seatSelection",
        meta: { middleware: [existingCheckout, existingReservation] },
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/SeatSelection.vue"
          ),
      },
      {
        path: ":eventId/:showId/summary",
        name: "reservationSummary",
        meta: { middleware: [existingCheckout] },
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/EventSummaryPage.vue"
          ),
      },
      {
        path: ":eventId/:showId/payment",
        name: "paymentSummary",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/payment/Index.vue"
          ),
      },

      {
        path: ":eventId/:showId/existing-checkout",
        meta: { middleware: [auth] },
        name: "existCheckout",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/ExistCheckout.vue"
          ),
      },

      {
        path: ":eventId/:showId/existing-reservation",
        meta: { middleware: [auth] },
        name: "existReservation",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/ExistReservation.vue"
          ),
      },
    ],
  },
];
