import store from "@/store";

export default function existingReservation({ next, to }) {
  const reservation = store.state.reservation.reservation;

  if (
    reservation &&
    reservation.event &&
    to.params.showId !== reservation.event.id
  ) {
    const url = `/event/${reservation.event.eventId}/${to.params.showId}/existing-reservation`;
    return next(url);
  }
  return next();
}
