import store from "@/store";

export default function existingCheckout({ next, to }) {
  const stripe = store.state.reservation.stripeSession;
  const expireTime = store.getters["reservation/stripeExpireTime"];

  const diff = Date.parse(expireTime) - Date.parse(new Date());

  if (stripe && stripe.url && diff > 0) {
    const url = `/event/${to.params.eventId}/${to.params.showId}/existing-checkout`;
    return next(url);
  } else if (stripe && stripe.url && diff <= 0) {
    store.dispatch("reservation/detach");
  }

  return next();
}
