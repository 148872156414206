<template>
  <div class="show-card">
    <div
      class="image"
      :style="`background-image: url(${event.posterUrlLandscape});`"
      @click.prevent="
        $router.push({
          name: 'eventDetail',
          params: { eventId: event.eventId },
        })
      "
    >
      <div class="card-bottom-overlay"></div>
      <div class="content">
        <div class="caption">{{ event.title }}</div>
        <div class="title">{{ event.title }}</div>
        <div class="date">Fri 28th April 2023</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["event"],
};
</script>
<style lang="scss">
.show-card {
  position: relative;
  cursor: pointer;
  .content {
    margin-left: 2rem;
    position: absolute;
    bottom: 5%;
    width: 100%;
    z-index: 30;
    .caption {
      font-size: 24px;
    }
    .title {
      font-size: 2rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    .date {
      font-size: 24px;
      color: #858585;
    }
    .action {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .image {
    height: 420px;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
  }
}
</style>
