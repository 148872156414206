import moment from "moment";
import _ from "lodash";

export function makeUniqueID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toLocaleUpperCase();
}

export function saveStorage(key, items) {
  localStorage.setItem(key, JSON.stringify(items));
}

export function removeStorage(key) {
  const storage = localStorage.getItem(key);
  if (storage !== null) {
    localStorage.removeItem(key);
  }
}

export function toDecimal(val) {
  if (!val) {
    return 0;
  }
  if (typeof val !== "string") {
    return parseFloat(val);
  }
  return parseFloat(val.replace(",", ""));
}
export function sumByCol(object, col = "name") {
  return _.sumBy(object, col);
}

export function orderBy(object, col = [], dir = []) {
  return _.orderBy(object, col, dir);
}

export function dateTimeFormat(date, format = "DD.MM.YYYY HH:mm") {
  if (!date) return "-";
  const d = new Date(date);
  return moment(d).format(format, "de");
}

export function currentDateTime(format = "DD.MM.YYYY HH:mm") {
  return moment().format(format, "de");
}

export function convertToUTCString(timestamp) {
  return new Date(timestamp.toDate()).toUTCString();
}

export function seatNameShorter(label) {
  return label;
  // const labelArray = label.split(" | ");
  // const newLabel = [];
  // labelArray.forEach((element) => {
  //   const a = element.split(" ");
  //   if (a[1]) {
  //     newLabel.push(a[1]);
  //   } else {
  //     newLabel.push(a[0]);
  //   }
  // });
  // return newLabel.join(" | ");
}

export function moneyFormat(amount) {
  amount = toDecimal(amount);
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
}

export function getStorage(key, key2 = null) {
  const items = localStorage.getItem(key);
  if (items !== null) {
    const j = JSON.parse(items);
    return key2 ? j[key2] : j;
  }
  return null;
}

export function mapJson(data, keys = []) {
  const newJson = [];
  keys.forEach((key) => {
    newJson[key] = data[key];
  });
  return newJson;
}

export function cloneCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function clearBrowserCache() {
  localStorage.clear();
  if ("serviceWorker" in navigator) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
}

export function addZero(number, len) {
  return String(number).padStart(len, "0");
}
export function scrollToTop() {
  window.scrollTo({ top: 0 });
}
